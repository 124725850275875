type ThemeType = typeof theme;

const theme = {
  fonts: {
    verdana: 'Verdana',
    agbook: 'AGBook',
    bookRoundedBold: 'AG Book Bold',
    bookRoundedRegular: 'AG Book Regular',
    bookRoundedMedium: 'AG Book Medium',
  },
  colors: {
    black: '#000',
    white: '#fff',
    sand: 'rgba(250, 236, 188, 0.7)',
    red: 'rgba(255, 104, 73, 1)',
    yellow: 'rgba(253, 215, 109, 1)',
    senape: 'rgba(247, 188, 44, 0.9)',
    lightSenape: 'rgba(247, 188, 44, 0.1)',
    lightGray: 'rgba(185, 176, 153, 1)',
    darkGray: 'rgba(104, 104, 104, 1)',
    purple: 'rgba(136, 34, 236, 1)',
    fucsia: 'rgba(255, 20, 221, 1)',
    violet: 'rgba(46, 7, 98, 1)',
    blue: 'rgba(18, 98, 164, 1)',
    smoke: 'rgba(64, 83, 99, 1)',
    pink: 'rgba(242, 144, 164, 1)',
    lightGreen: 'rgba(172, 204, 69, 1)',
    swampGreen: 'rgba(165, 195, 75, 0.65)',
    darkPurple: 'rgba(122, 0, 230, 1)',
    lightBlue: 'rgba(7, 96, 166, 1)',
  },
};
export default theme;

export type {ThemeType};

export type Colors = keyof typeof theme.colors;
