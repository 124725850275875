import styled from 'styled-components';
import sliderValuesBg from '../assets/babies/slider-values.svg';

type InputRangeProps = {
  value: string;
  onChange: any;
};

const InputRange = ({value, onChange}: InputRangeProps) => {
  return (
    <RangeContainer>
      <Range type="range" min="1" max="7" value={value} onChange={onChange} />
      <RangeValues src={sliderValuesBg} alt="Values" />
    </RangeContainer>
  );
};

export default InputRange;

const RangeContainer = styled.div`
  padding: 40px 0 0;
  width: 350px;
`;

const Range = styled.input`
  appearance: none;
  background-image: linear-gradient(to right, #e0e5a6, #31a79c);
  width: 100%;
  height: 50px;
  border-radius: 30px;
  position: relative;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 0 0 0 16px rgba(255, 255, 255, 0.4);
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 0 0 0 16px rgba(255, 255, 255, 0.4);
  }
`;

const RangeValues = styled.img`
  margin-top: 10px;
  width: 104%;
  margin-left: -2%;
`;
