import styled from 'styled-components';

const RethinkLink = styled.a<{
  inversed?: boolean;
  color: string;
  disabled?: boolean;
}>`
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.bookRoundedBold};
  font-size: 16pt;
  background: ${(props) => props.theme.colors[props.color]};
  border: none;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  color: ${(props) =>
    props.inversed ? props.theme.colors.white : props.theme.colors.black};
  border-radius: 8px;
  padding: 14px 16px;
  min-width: 130px;
  transform: skew(-10deg);
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};

  & + & {
    margin-left: 3vw;
  }
`;

export default RethinkLink;
