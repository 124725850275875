import {Link} from 'react-router-dom';
import styled from 'styled-components';

const LinkButton = styled(Link)<{$outlined?: boolean}>`
  color: inherit;
  text-transform: uppercase;
  font-weight: bold;
  font-size: clamp(16px, 3.5vw, 22px);
  text-decoration: none;
  letter-spacing: 1px;

  ${({$outlined}) =>
    $outlined &&
    `
    border-radius: 5px;
    border: 1px solid black;
    padding: 5px 10px;
  `}
`;

export default LinkButton;
