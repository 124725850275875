import {db} from '../utils/firebase';
import {ref, set, onDisconnect} from 'firebase/database';
import {useEffect} from 'react';

export default function useFirebasePresence(id: any) {
  useEffect(() => {
    const reference = ref(db, `sanofi-evento/presenze-approfondimento/${id}`);

    set(reference, true).then(() => console.log('Online presence set'));
    // onDisconnect(reference)
    //   .remove()
    //   .then(() => console.log('On disconnect function configured.'));
  }, []);
}
