import {createGlobalStyle} from 'styled-components';
import {ThemeType} from './';

const GlobalStyle = createGlobalStyle<{theme: ThemeType}>`

@font-face {
  font-family: 'AGBook';
  src: url('/fonts/AGBookRounded-BoldCn.woff2') format('woff2'),
      url('/fonts/AGBookRounded-BoldCn.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AGBook';
  src: url('/fonts/AGBookRounded-Regular.woff2') format('woff2'),
      url('/fonts/AGBookRounded-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'AG Book Medium';
    src: url('AGBookRounded-Medium.woff2') format('woff2'),
        url('/fonts/AGBookRounded-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AG Book Regular';
    src: url('AGBookRounded-Regular.woff2') format('woff2'),
        url('/fonts/AGBookRounded-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AG Book Bold';
    src: url('AGBookRounded-BoldCn.woff2') format('woff2'),
        url('/fonts/AGBookRounded-BoldCn.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

body {
  font-family: ${(props) => props.theme.fonts.agbook};
  font-weight: normal;
}
`;

export default GlobalStyle;
