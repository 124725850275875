import styled from 'styled-components';
import Button from './Buttons/Button';

interface QuitModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

export default function QuitModal({onClose, onConfirm}: QuitModalProps) {
  return (
    <ModalWrap>
      <Modal>
        <Close onClick={onClose} />
        <h2>Sicuro di voler abbandonare il gioco?</h2>
        <FlexWrap>
          <Button onClick={onConfirm}>Esci</Button>
          <Button onClick={onClose}>Continua</Button>
        </FlexWrap>
      </Modal>
    </ModalWrap>
  );
}

const ModalWrap = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: fleX;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background: rgba(0, 0, 0, 0.3);
`;

const Modal = styled.div`
  width: 60vw;
  max-width: 600px;
  height: 40vh;
  max-height: 350px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: ${({theme}) => theme.colors.purple};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 30px;
  position: relative;
`;

const FlexWrap = styled.div`
  margin-top: 20px;
  display: flex;
`;

const Close = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after,
  &::before {
    content: '';
    width: 30px;
    height: 4px;
    background: ${({theme}) => theme.colors.purple};
    position: absolute;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`;
