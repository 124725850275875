import styled from 'styled-components';
import Layout from '../components/Layout';
import Rank from '../components/Rank';
import HomeIcon from '../assets/home.svg';
import {useNavigate} from 'react-router-dom';
import Code from '../components/Code';

export default function BibliographyScreen() {
  const navigate = useNavigate();

  return (
    <Layout bgScreen="quiz">
      <HomeButton
        src={HomeIcon}
        alt="back to home"
        onClick={() => navigate('/')}
      />
      <Title>Bibliografia</Title>
      <Rank />
      <Code />
    </Layout>
  );
}

const Title = styled.h1`
  color: ${({theme}) => theme.colors.red};
  font-size: 22px;
  border: 6px solid ${({theme}) => theme.colors.red};
  padding: 12px 60px;
  border-radius: 35px;
  font-weight: bold;
`;

const HomeButton = styled.img`
  position: absolute;
  top: 3vh;
  left: 3vw;
  width: 40px;
`;
