import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import BigButton from '../components/Buttons/BigButton';
import LinkButton from '../components/Buttons/LinkButton';
import Layout from '../components/Layout';
import yellowImg from '../assets/home/yellow.png';
import redImg from '../assets/home/red.png';
import blueImg from '../assets/home/blue.png';

export default function WelcomeScreen() {
  const navigate = useNavigate();

  return (
    <Layout centered bgScreen="white">
      <FlexWrap>
        <StyledBigButton
          onClick={() => navigate('/quiz')}
          image={yellowImg}
          size="large"
        >
          Gioca a Play It Safe
        </StyledBigButton>
        <StyledBigButton
          onClick={() => navigate('/learn-more-about-rsv')}
          image={blueImg}
          size="large"
        >
          Approfondisci l'RSV
        </StyledBigButton>
        <StyledBigButton
          onClick={() => navigate('/rethinkrsv-qrcode')}
          image={redImg}
          size="large"
        >
          #RETHINKRSV
        </StyledBigButton>
      </FlexWrap>
      <StyledLinkButton to="/medical-area">
        Iscriviti all'area dedicata ai medici
      </StyledLinkButton>
    </Layout>
  );
}

const StyledBigButton = styled(BigButton)`
  font-family: ${({theme}) => theme.fonts.agbook};
  color: ${({theme}) => theme.colors.darkGray};
`;

const FlexWrap = styled.div`
  display: flex;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-top: 10vh;
  color: ${({theme}) => theme.colors.blue};
  font-family: ${({theme}) => theme.fonts.agbook};
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background: ${({theme}) => theme.colors.blue};
    position: absolute;
    bottom: -3px;
    left: 0;
  }
`;
