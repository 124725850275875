import {PropsWithChildren} from 'react';
import styled from 'styled-components';

interface RethinkButtonProps extends PropsWithChildren<{}> {
  onClick: () => void;
  disabled?: boolean;
  inversed?: boolean;
  color: string;
}

const RethinkButton = (props: RethinkButtonProps) => {
  return (
    <Button {...props}>
      <Label>{props.children}</Label>
    </Button>
  );
};

const Button = styled.button<{
  inversed?: boolean;
  color: string;
  disabled?: boolean;
}>`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.bookRoundedBold};
  font-size: 16pt;
  background: ${(props) => props.theme.colors[props.color]};
  border: none;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};

  color: ${(props) =>
    props.inversed ? props.theme.colors.white : props.theme.colors.black};
  border-radius: 8px;
  padding: 14px 16px;
  min-width: 130px;
  transform: skew(-10deg);
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};

  & + & {
    margin-left: 3vw;
  }
`;

const Label = styled.span`
  display: inline-block;
  transform: skew(10deg);
`;

export default RethinkButton;
