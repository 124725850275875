import styled from 'styled-components';

const Code = () => {
  return (
    <Container>
      <span>MAT-IT-2201106</span>
    </Container>
  );
};

export default Code;

const Container = styled.div`
  position: fixed;
  bottom: 8px;
  right: 20px;
  font-size: 10px;
  letter-spacing: 0.5px;
  padding: 4px;
`;
