import styled from 'styled-components';
import {useRef, useState} from 'react';
import InputRange from '../components/Slider';
import bg01 from '../assets/babies/bg-01.png';
import bg02 from '../assets/babies/bg-02.png';
import bg03 from '../assets/babies/bg-03.png';
import bg04 from '../assets/babies/bg-04.png';
import bg05 from '../assets/babies/bg-05.png';
import bg07 from '../assets/babies/bg-06.png';
import content01 from '../assets/babies/content-01.svg';
import content02 from '../assets/babies/content-02.svg';
import content03 from '../assets/babies/content-03.png';
import content04 from '../assets/babies/content-04.png';
import content05 from '../assets/babies/content-05.png';
import content06 from '../assets/babies/card.svg';
import ButtonPrevBg from '../assets/babies/icon-arrow-left.svg';
import ButtonNextBg from '../assets/babies/icon-arrow-right.svg';
import HomeImage from '../assets/babies/home-icon.svg';
import LogoImage from '../assets/sanofi-logo.svg';
import RSVCounter from '../components/RSVCounter';
import {Link} from 'react-router-dom';
import Code from '../components/Code';

const STEPS = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];

const BGMap: any = {
  one: bg01,
  two: bg02,
  three: bg03,
  four: bg04,
  five: bg05,
  six: bg01,
  seven: bg07,
};

const LearnMoreAboutRSVScreen = () => {
  const [step, setStep] = useState<any>(STEPS[0]);
  const [question1, setQuestion1] = useState('1');
  const [question2, setQuestion2] = useState('1');
  const [question3, setQuestion3] = useState('1');

  const [counter, setCounter] = useState(true);

  const refVideo1 = useRef<any>();
  const refVideo2 = useRef<any>();
  const refVideo3 = useRef<any>();

  const playVideoOne = () => {
    refVideo1.current.play();
  };
  const playVideoTwo = () => {
    refVideo2.current.play();
  };
  const playVideoThree = () => {
    refVideo3.current.play();
  };

  const onChange1 = (e: any) => {
    setQuestion1(e.target.value);
  };

  const onChange2 = (e: any) => {
    setQuestion2(e.target.value);
  };

  const onChange3 = (e: any) => {
    setQuestion3(e.target.value);
  };

  const handlePrev = () => {
    const index = STEPS.indexOf(step);

    if (index === 0) {
      return;
    }

    setStep(STEPS[index - 1]);
  };

  const handleNext = () => {
    const index = STEPS.indexOf(step);

    if (index === 6) {
      return;
    }

    setStep(STEPS[index + 1]);
  };

  const renderMain = () => {
    if (step === STEPS[0]) {
      return (
        <>
          <Content src={content01} />
          <InputRange value={question1} onChange={onChange1} />
        </>
      );
    }

    if (step === STEPS[1]) {
      return (
        <>
          <Content src={content02} />
          <InputRange value={question2} onChange={onChange2} />
        </>
      );
    }

    if (step === STEPS[2]) {
      return <Content src={content03} />;
    }

    if (step === STEPS[3]) {
      return <Content src={content04} />;
    }

    if (step === STEPS[4]) {
      return (
        <Videos>
          <VideosBackground src={content05} />
          <Video01>
            <video ref={refVideo1} src="/video-1.mp4" />
          </Video01>
          <Video02>
            <video ref={refVideo2} src="/video-2.mp4" />
          </Video02>
          <Video03>
            <video ref={refVideo3} src="/video-3.mp4" />
          </Video03>
          <VideoButton01 onClick={playVideoOne} />
          <VideoButton02 onClick={playVideoTwo} />
          <VideoButton03 onClick={playVideoThree} />
        </Videos>
      );
    }

    if (step === STEPS[5]) {
      return (
        <>
          <Content src={content02} />
          <InputRange value={question3} onChange={onChange3} />
        </>
      );
    }

    if (step === STEPS[6]) {
      return (
        <>
          {counter && <RSVCounter remove={() => setCounter(false)} />}
          <Card src={content06} />
        </>
      );
    }
  };

  return (
    <Container style={{backgroundImage: `url(${BGMap[step]})`}}>
      {renderMain()}
      <NavigationContainer>
        <ButtonPrev disabled={step === 'one'} onClick={handlePrev} />
        <ButtonNext disabled={step === 'seven'} onClick={handleNext} />
      </NavigationContainer>
      <Logo src={LogoImage} alt="Sanofi" />
      <Link to="/">
        <HomeButton src={HomeImage} alt="Home" />
      </Link>
      <Code />
    </Container>
  );
};

export default LearnMoreAboutRSVScreen;

const Logo = styled.img`
  width: 150px;
  position: absolute;
  top: 1vh;
  right: 4vw;
  z-index: 2;
`;

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Content = styled.img`
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const ButtonPrev = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 10px;
  border: none;
  background-image: url(${ButtonPrevBg});
  cursor: pointer;

  &:disabled {
    visibility: hidden;
  }
`;

const ButtonNext = styled(ButtonPrev)`
  background-image: url(${ButtonNextBg});
`;

const Videos = styled.div`
  position: relative;
  max-width: 94%;
  margin-top: -8%;
`;

const VideosBackground = styled.img`
  width: 100%;
`;

const Video01 = styled.div`
  position: absolute;
  width: 25.5%;
  height: 32.5%;
  top: 31.5%;
  left: 4.5%;

  video {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Video02 = styled(Video01)`
  left: 38.5%;
`;

const Video03 = styled(Video01)`
  left: 72.5%;
`;

const VideoButton01 = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  top: 65%;
  left: 14.5%;
  opacity: 0.3;
  cursor: pointer;
`;

const VideoButton02 = styled(VideoButton01)`
  left: 48.7%;
`;

const VideoButton03 = styled(VideoButton01)`
  left: 82.7%;
`;

const Card = styled.img`
  max-width: 50%;
`;

const HomeButton = styled.img`
  position: absolute;
  display: block;
  width: 50px;
  top: 30px;
  left: 50px;
`;
