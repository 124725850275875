export const animals = [
  'Cavallo',
  'Panda',
  'Rana',
  'Lince',
  'Tigre',
  'Delfino',
  'Iguana',
  'Giraffa',
  'Pappagallo',
  'Elfante',
];

export const colors = [
  'bianco',
  'verde',
  'rosa',
  'viola',
  'arancione',
  'giallo',
  'blu',
  'azzurro',
  'rosso',
  'lilla',
];
