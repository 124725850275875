import {useRef, useState, useEffect, ChangeEvent} from 'react';
import styled from 'styled-components';
import {convertImageData64toFile, drawImage, isIOS} from '../utils/utils';
import {LAYOUTS} from '../config/rsvLayouts';
import RethinkButton from '../components/Buttons/RethinkButton';
import mobileBg from '../assets/rethink-rsv/mobile-bg.png';
import logo from '../assets/sanofi-logo.svg';
import RethinkLink from '../components/Buttons/RethinkLink';
import Code from '../components/Code';

export default function RSVScreen() {
  const [step, setStep] = useState('question');
  const [imageData, setImageData] = useState('');
  const [canShare, setCanShare] = useState(true);
  const [answerText, setAnswerText] = useState('');
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (step === 'share') {
      const file = convertImageData64toFile(imageData);
      const shareImage = {files: [file], title: '#rethinkrsv'};
      if (!navigator.canShare || !navigator.canShare(shareImage)) {
        setCanShare(false);
      }
    }
  }, [step, imageData]);

  const handleAnswerSubmission = () => {
    const layoutIndex = Math.floor(Math.random() * 3);

    const canvas = canvasRef.current;
    if (canvas) {
      drawImage(canvas, LAYOUTS[layoutIndex], answerText, () => {
        setImageData(canvas.toDataURL());
        setStep('confirm');
      });
    }
  };

  const handleBack = () => {
    setStep('question');
  };

  const handleConfirm = () => {
    setStep('share');

    try {
      const file = convertImageData64toFile(imageData);
      const formData = new FormData();
      formData.append('image', file);
      fetch('https://improntaadv.com/sviluppo-web/clienti/sanofi/rethink.php', {
        method: 'POST',
        body: formData,
      });
    } catch (error) {}
  };

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 140) {
      setAnswerText(e.currentTarget.value);
    }
  };

  const handleShare = async () => {
    const file = convertImageData64toFile(imageData);

    const shareImage = isIOS()
      ? {files: [file]}
      : {files: [file], title: '#rethinkrsv'};
    await navigator.share(shareImage);
  };

  return (
    <MainContainer style={{backgroundImage: `url(${mobileBg})`}}>
      <LogoContainer>
        <Logo src={logo} alt="Logo Sanofi" />
      </LogoContainer>

      {step === 'question' && (
        <>
          <Title>
            Perché tutti i bambini hanno bisogno di essere protetti dall’RSV?
          </Title>
          <>
            <TextArea onChange={handleTextChange} value={answerText} />
            <CharCounter>{answerText.length}/140 caratteri</CharCounter>
          </>
          <RethinkButton
            onClick={handleAnswerSubmission}
            color="pink"
            disabled={answerText.length === 0}
          >
            Continua
          </RethinkButton>
        </>
      )}

      {step === 'confirm' && (
        <>
          <Title>Risultato finale!{'\n'} Va bene?</Title>
          <ConfirmImage src={imageData} />
          <ButtonRow>
            <RethinkButton onClick={handleBack} color="lightGreen">
              No, modifica
            </RethinkButton>
            <RethinkButton onClick={handleConfirm} color="pink">
              Sì, continua
            </RethinkButton>
          </ButtonRow>
        </>
      )}
      {step === 'share' && (
        <>
          <Title>Grazie per la{'\n'} partecipazione!</Title>
          <InfoText>
            Ora puoi scaricare l’immagine sul tuo dispositivo o condividerla
            direttamente!
          </InfoText>
          <ShareRow>
            <FinalImage src={imageData} />
            <ShareColumn>
              {canShare && (
                <RethinkButton onClick={handleShare} color="lightGreen">
                  condividi
                </RethinkButton>
              )}
              <Download
                color="pink"
                download="#rethinkrsv.png"
                href={imageData}
              >
                scarica
              </Download>
            </ShareColumn>
          </ShareRow>
          <PersonalDataDisclaimer>
            <i>
              Ti informiamo che la presente attività non comporterà alcuna
              attività di trattamento e/o tracciamento dei tuoi dati personali
            </i>
          </PersonalDataDisclaimer>
          <Title>
            Non dimenticare di iscriverti all'area dedicata ai medici del nostro
            sito
          </Title>
          <RethinkLink
            href="https://www.vaccini.sanofipasteur.it/registrazione-nuovo-utente"
            color="darkPurple"
            inversed
          >
            Iscriviti
          </RethinkLink>
        </>
      )}

      <Canvas ref={canvasRef}></Canvas>
      <Code />
    </MainContainer>
  );
}

const Canvas = styled.canvas`
  width: 100%;
  display: none;
`;

const ConfirmImage = styled.img`
  width: 100%;
  margin-bottom: 50px;
  -webkit-box-shadow: 4px 1px 16px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 4px 1px 16px 0px rgba(0, 0, 0, 0.23);
`;

const TextArea = styled.textarea.attrs({
  maxLength: 200,
  placeholder: 'Scrivi qui la tua risposta...',
})`
  background-color: ${({theme}) => theme.colors.swampGreen};
  border: none;
  border-bottom: 6px solid #000000;
  font-family: ${({theme}) => theme.fonts.bookRoundedRegular};
  font-size: 16pt;
  min-height: 300px;
  width: 85%;
  padding: 24px;
  margin-bottom: 7px;

  ::placeholder {
    color: ${({theme}) => theme.colors.black};
    opacity: 1;
  }
`;

const MainContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;

  flex-direction: column;
  position: relative;
  padding: 20px 20px 40px;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Title = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-family: ${({theme}) => theme.fonts.bookRoundedBold};
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: ${({theme}) => theme.colors.lightBlue};
  margin-bottom: 58px;
  white-space: pre-line;
`;

const InfoText = styled.p`
  font-family: ${({theme}) => theme.fonts.bookRoundedRegular};
  font-size: 18px;
  margin-bottom: 50px;
  color: ${({theme}) => theme.colors.black};
  text-align: center;
`;

const CharCounter = styled.p`
  font-family: ${({theme}) => theme.fonts.bookRoundedRegular};
  color: ${({theme}) => theme.colors.black};
  font-size: 14pt;
  text-align: center;
  margin-bottom: 50px;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5vh;
`;

const Logo = styled.img`
  width: 140px;
  z-index: 2;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ShareRow = styled.div`
  width: 100%;
  height: calc(50vw - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ShareColumn = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Download = styled(RethinkLink)`
  margin-top: 25px;
`;

const FinalImage = styled.img`
  width: 50%;
  flex: 1;
`;

const PersonalDataDisclaimer = styled(InfoText)`
  font-size: 8px;
  margin-bottom: 50px;
`;
