import useFirebasePresence from '../hooks/useFirebasePresence';

const Counter = ({remove}: {remove: () => void}) => {
  useFirebasePresence(Date.now());

  remove();
  return null;
};

export default Counter;
