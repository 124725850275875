import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../components/Layout';
import QuestionWrap from '../components/Question';
import useQuiz from '../hooks/useQuiz';
import quizLogo from '../assets/quiz-logo.png';
import HomeIcon from '../assets/home.svg';
import QuitModal from '../components/QuitModal';
import BigButton from '../components/Buttons/BigButton';
import playIcon from '../assets/play.svg';
import rankIcon from '../assets/rank.svg';
import refreshIcon from '../assets/refresh.svg';
import checkIcon from '../assets/check.svg';
import Button from '../components/Buttons/Button';
import fantasy from '../assets/purple-fantasy.png';
import AnimatedElements from '../components/AnimatedElements';
import Code from '../components/Code';

export default function QuizScreen() {
  const {
    step,
    nickname,
    selected: question,
    handleNickName,
    handleStart,
    handleConfirm,
    handleAnswer,
    showResult,
    seconds,
    handleContinue,
    current,
  } = useQuiz();

  const [initialResize, setInitialResize] = useState<boolean>(false);
  const [quitModal, setQuitModal] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    step === 'end' && navigate('/rank', {state: nickname});
  }, [step, navigate, nickname]);

  useEffect(() => {
    setTimeout(() => setInitialResize(true), 1000);
  }, []);

  if (step === 'start') {
    return (
      <Layout centered bgScreen="quiz">
        <AnimatedElements show={true} />
        <HomeButton
          src={HomeIcon}
          alt="back to home"
          onClick={() => navigate('/')}
        />
        <QuizLogo src={quizLogo} alt="quiz logo" resize={true} />
        <AnimatedText show={true}>
          Non vorrai gettarti nella competizione senza un{' '}
          <b>nome di battaglia</b>!<br />
          Per questo ti abbiamo messo a disposizione un Nickname Generator che
          saprà conferirti un appellativo degno del tuo valore
          <br />
          Scoprilo subito!
        </AnimatedText>
        <PurpleWrap show={true}>
          <h3>Nickname generator</h3>
          <h4>
            Nickname: <span>{nickname}</span>
          </h4>
          <FlexWrap>
            <LittleButton onClick={handleNickName} bgColor="red">
              <img src={refreshIcon} alt="" />
            </LittleButton>
            <LittleButton
              bgColor="senape"
              onClick={handleConfirm}
              disabled={nickname === ''}
            >
              <img src={checkIcon} alt="" />
            </LittleButton>
          </FlexWrap>
        </PurpleWrap>
        <Code />
      </Layout>
    );
  }

  if (step === 'confirm') {
    return (
      <Layout centered bgScreen="quiz">
        <AnimatedElements show={true} />
        <HomeButton
          src={HomeIcon}
          alt="back to home"
          onClick={() => navigate('/')}
        />
        <QuizLogo src={quizLogo} alt="quiz logo" resize={true} />
        <AnimatedFlexWrap show={initialResize} isConfirm={true}>
          <img src={fantasy} alt="fantasy" className="bg" />
          <PurpleText>Ok {nickname} sei pronto a giocare?</PurpleText>
          <StyledButton onClick={handleStart}>Start game</StyledButton>
        </AnimatedFlexWrap>
        <Code />
      </Layout>
    );
  }

  if (step === 'quiz' && question) {
    return (
      <Layout centered bgScreen="quiz">
        <HomeButton
          src={HomeIcon}
          alt="back to home"
          onClick={() => setQuitModal(true)}
        />
        <QuestionWrap
          seconds={seconds}
          question={question}
          handleAnswer={handleAnswer}
          showResult={showResult}
          handleContinue={handleContinue}
          current={current}
        />
        {quitModal && (
          <QuitModal
            onClose={() => setQuitModal(false)}
            onConfirm={() => navigate('/')}
          />
        )}
        <Code />
      </Layout>
    );
  }

  return (
    <Layout centered bgScreen="quiz">
      <AnimatedElements show={initialResize} />
      <HomeButton
        src={HomeIcon}
        alt="back to home"
        onClick={() => navigate('/')}
      />
      <QuizLogo src={quizLogo} alt="quiz logo" resize={initialResize} />
      <AnimatedText show={initialResize}>
        Benvenuto/a a <b>PLAY IT SAFE</b>, un quiz che ti permette di verificare
        la tua conoscenza su <b>RSV</b> e <b>Meningococco</b>.<br /> Rispondi a
        4 domande nel minor tempo possibile: il più veloce guadagna più punti.
        <br />
        Fatti valere, buon divertimento!
      </AnimatedText>
      <AnimatedFlexWrap show={initialResize}>
        <BigButton
          onClick={handleNickName}
          image={playIcon}
          size="small"
          className="play"
        >
          Gioca
        </BigButton>
        <BigButton
          size="small"
          onClick={() => navigate('/rank')}
          image={rankIcon}
        >
          Classifica
        </BigButton>
      </AnimatedFlexWrap>
      <Code />
    </Layout>
  );
}

const AnimatedFlexWrap = styled.div<{show: boolean; isConfirm?: boolean}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 800ms;
  opacity: ${({show}) => (show ? '1' : '0')};
  transform: ${({show}) => (show ? 'translateY(-2vh)' : 'translateY(0vh)')};
  position: relative;
  background: ${({theme}) => theme.colors.sand};
  padding: ${({isConfirm}) => (isConfirm ? '50px 10px' : '30px 50px')};
  border-radius: 30px;
  ${({isConfirm}) => isConfirm && 'max-width: 450px;'}
  width: 45vw;
  min-width: 350px;

  .bg {
    width: 70%;
    position: absolute;
    top: 60%;
    left: 50%;
    opacity: 0.6;
    transform: translate(-50%, -40%);
    z-index: -1;
  }
`;

const PurpleWrap = styled.div<{show: boolean}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 800ms;
  opacity: ${({show}) => (show ? '1' : '0')};
  transform: ${({show}) => (show ? 'translateY(-2vh)' : 'translateY(0vh)')};
  position: relative;
  background: ${({theme}) => theme.colors.purple};
  padding: 30px 50px;
  width: 45vw;
  border-radius: 30px;
  flex-direction: column;

  h3 {
    color: ${({theme}) => theme.colors.yellow};
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  h4 {
    border-bottom: 2px solid ${({theme}) => theme.colors.yellow};
    padding-bottom: 10px;
    width: 100%;
    font-weight: 400;
    color: ${({theme}) => theme.colors.yellow};
    margin-bottom: 20px;
    text-align: center;
    span {
      color: ${({theme}) => theme.colors.white};
      font-style: italic;
    }
  }
`;

const QuizLogo = styled.img<{resize: boolean}>`
  width: ${({resize}) => (resize ? '40vw' : '60vw')};
  transform: ${({resize}) =>
    resize ? 'translateY(-3vh)' : 'translateY(22vh)'};
  transition: all 800ms;
`;

const HomeButton = styled.img`
  position: absolute;
  top: 3vh;
  left: 3vw;
  width: 40px;
  z-index: 2;
`;

const PurpleText = styled.h2`
  color: ${({theme}) => theme.colors.purple};
  margin-bottom: 10vh;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-style: italic;

  @media (max-width: 768px) {
    margin-bottom: 8vh;
  }
`;

const AnimatedText = styled.h3<{show: boolean}>`
  transition: all 800ms;
  color: ${({theme}) => theme.colors.purple};
  opacity: ${({show}) => (show ? '1' : '0')};
  transform: ${({show}) => (show ? 'translateY(-2vh)' : 'translateY(0vh)')};
  text-align: center;
  margin-bottom: 3vh;
  max-width: 700px;
  font-weight: 300;
`;

const FlexWrap = styled.div`
  display: flex;
`;

const LittleButton = styled.button<{bgColor: 'red' | 'senape'}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${({theme, bgColor}) => theme.colors[bgColor]};
  width: 50px;
  height: 50px;
  border-radius: 100%;

  img {
    width: 50%;
  }

  & + & {
    margin-left: 30px;
  }
`;

const StyledButton = styled(Button)`
  padding: 20px 50px;
  font-size: 20px;
`;
