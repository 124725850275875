import styled from 'styled-components';
import Layout from '../components/Layout';
import Rank from '../components/Rank';
import useRank from '../hooks/useRank';
import HomeIcon from '../assets/home.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import Code from '../components/Code';

export default function RankScreen() {
  const {rank, loading} = useRank();
  const navigate = useNavigate();
  const location = useLocation();

  const nickname = location.state as string | undefined;

  return (
    <Layout bgScreen="quiz">
      <HomeButton
        src={HomeIcon}
        alt="back to home"
        onClick={() => navigate('/')}
      />
      <Title>Classifica</Title>
      {loading && <Loader>Caricamento...</Loader>}
      {rank.length > 0 && <Rank rank={rank} nickname={nickname} />}
      <Code />
    </Layout>
  );
}

const Title = styled.h1`
  color: ${({theme}) => theme.colors.red};
  font-size: 22px;
  border: 6px solid ${({theme}) => theme.colors.red};
  padding: 12px 60px;
  border-radius: 35px;
  font-weight: bold;
`;

const HomeButton = styled.img`
  position: absolute;
  top: 3vh;
  left: 3vw;
  width: 40px;
`;

const Loader = styled.h3`
  color: ${({theme}) => theme.colors.purple};
  margin-top: 3vh;
`;
