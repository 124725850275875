import useFirebaseVal from './useFirebaseVal';

const mockRank: RankItem[] = [
  {
    nickname: 'Pippi2',
    score: 2345365,
  },
  {
    nickname: 'Bello23435',
    score: 3257,
  },
  {
    nickname: 'Pluot2',
    score: 265,
  },
  {
    nickname: 'asad435',
    score: 32532547,
  },
  {
    nickname: 'ciccio3',
    score: 325347,
  },
  {
    nickname: 'ciccio34',
    score: 3257,
  },
];

export default function useRank() {
  const [rank] = useFirebaseVal('risultati');

  return {
    rank: rank ? (Object.values(rank) as RankItem[]) : [],
    loading: !rank,
  };
}
