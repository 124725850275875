import React from 'react';
import styled, {keyframes} from 'styled-components';
import violetAsterisk from '../assets/animated/violet-asterisk.svg';
import orangeCircle from '../assets/animated/orange-circle.svg';
import orangePlus from '../assets/animated/orange-plus.svg';
import whiteAsteriskImg from '../assets/animated/white-asterisk.svg';
import violetCircle from '../assets/animated/violet-circle.svg';
import whitePlus from '../assets/animated/white-plus.svg';
import whiteBorderCircle from '../assets/animated/white-border-circle.svg';
import violetBorderCircle from '../assets/animated/violet-border-circle.svg';
import whiteCircle from '../assets/animated/white-circle.svg';

interface AnimatedElementsProps {
  show: boolean;
}

export default function AnimatedElements({show}: AnimatedElementsProps) {
  return (
    <>
      <Img src={violetAsterisk} className="violet-asterisk" show={show} />
      <Img src={orangeCircle} className="orange-circle" show={show} />
      <Img src={whiteAsteriskImg} className="white-asterisk" show={show} />
      <Img src={orangePlus} className="orange-plus" show={show} />
      <Img src={violetCircle} className="violet-circle" show={show} />
      <Img src={whiteCircle} className="white-circle" show={show} />
      <Img src={whitePlus} className="white-plus" show={show} />
      <Img src={whitePlus} className="white-plus second" show={show} />
      <Img
        src={whiteBorderCircle}
        className="white-border-circle"
        show={show}
      />
      <Img
        src={violetBorderCircle}
        className="violet-border-circle"
        show={show}
      />
    </>
  );
}

const animation1 = keyframes`
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(10px)
  }
`;

const animation2 = keyframes`
  from {
    transform: translateY(10px)
  }
  to {
    transform: translateY(0px)
  }
`;

const Img = styled.img<{show: boolean}>`
  position: absolute;
  opacity: ${({show}) => (show ? '1' : '0')};
  transition: all 500ms;

  &.violet-asterisk {
    width: 4vw;
    bottom: 3vh;
    left: 45vw;
    animation: 1s linear 1s infinite alternate ${animation1};
  }

  &.white-asterisk {
    width: 5vw;
    bottom: 10vh;
    right: 10vw;
    animation: 1.5s linear 1s infinite alternate ${animation1};
  }

  &.orange-circle {
    width: 3vw;
    bottom: 45vh;
    left: 20vw;
    animation: 1s linear 1s infinite alternate ${animation2};
  }

  &.violet-circle {
    width: 2vw;
    bottom: 28vh;
    left: 18vw;
    animation: 2s linear 1s infinite alternate ${animation1};
  }

  &.white-circle {
    width: 7vw;
    bottom: 70vh;
    right: 7vw;
    animation: 1.5s linear 1s infinite alternate ${animation2};
    ${({show}) => show && 'opacity: 0.8;'}
  }

  &.orange-plus {
    width: 2vw;
    bottom: 30vh;
    right: 17vw;
    animation: 2s linear 1s infinite alternate ${animation2};
  }

  &.white-plus {
    width: 4vw;
    bottom: 60vh;
    left: 5vw;
    animation: 2s linear 1s infinite alternate ${animation1};

    &.second {
      bottom: 10vh;
      left: 20vw;
      animation: 1.5s linear 1s infinite alternate ${animation2};
    }
  }

  &.white-border-circle {
    width: 5vw;
    bottom: 50vh;
    right: 28vw;
    animation: 1.5s linear 1s infinite alternate ${animation1};
  }

  &.violet-border-circle {
    width: 6vw;
    bottom: 45vh;
    left: -3vw;
    animation: 2.5s linear 1s infinite alternate ${animation2};
  }
`;
