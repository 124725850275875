import styled from 'styled-components';
import bg07 from '../assets/babies/bg-06.png';
import content06 from '../assets/babies/card.svg';
import HomeImage from '../assets/babies/home-icon.svg';
import LogoImage from '../assets/sanofi-logo.svg';
import {Link} from 'react-router-dom';
import Code from '../components/Code';

const QRCodeScreen = () => {
  return (
    <Container style={{backgroundImage: `url(${bg07})`}}>
      <Card src={content06} />
      <Logo src={LogoImage} alt="Sanofi" />
      <Link to="/">
        <HomeButton src={HomeImage} alt="Home" />
      </Link>
      <Code />
    </Container>
  );
};

export default QRCodeScreen;

const Logo = styled.img`
  width: 150px;
  position: absolute;
  top: 1vh;
  right: 4vw;
  z-index: 2;
`;

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Card = styled.img`
  max-width: 50%;
`;

const HomeButton = styled.img`
  position: absolute;
  display: block;
  width: 50px;
  top: 30px;
  left: 50px;
`;
