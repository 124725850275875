import layoutOne from '../assets/rethink-rsv/layout-1.jpg';
import layoutTwo from '../assets/rethink-rsv/layout-2.jpg';
import layoutThree from '../assets/rethink-rsv/layout-3.jpg';

export const LAYOUTS: RsvLayout[] = [
  {
    image: layoutOne,
    startingX: 643,
    startingY: 298,
    maxRowLength: 17,
    height: 440,
  },
  {
    image: layoutTwo,
    startingX: 93,
    startingY: 511,
    maxRowLength: 17,
    height: 437,
  },
  {
    image: layoutThree,
    startingX: 280,
    startingY: 738,
    maxRowLength: 24,
    height: 270,
  },
];
