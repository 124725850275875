import {animals, colors} from '../config/nicknames';

// TODO: add score logic
export const scoreCalculator = (values: number[]) =>
  values.reduce((prev, curr) => prev + curr, 0);

export const indexToCharacter = (index: number) => {
  if (index === 0) {
    return 'a';
  }
  if (index === 1) {
    return 'b';
  }
  if (index === 2) {
    return 'c';
  }
  return 'd';
};

export const getRandomQuestions = (questions: Question[]) => {
  let arr = questions;
  let selection = [];
  for (let step = 0; step < 2; step++) {
    const random = Math.floor(Math.random() * arr.length);
    const selected = arr[random];
    arr = arr.filter((el) => el.title !== selected.title);
    selection.push(selected);
  }
  return selection;
};

export const randomizeOrder = (questions: Question[]) => {
  let arr = questions;
  let ordered = [];
  for (let step = 0; step < 4; step++) {
    if (step === 0 || step === 2) {
      const selected = arr.find((el) => el.topic === 'meningo');
      arr = arr.filter((el) => el.title !== selected?.title);
      ordered.push(selected!);
    } else {
      const selected = arr.find((el) => el.topic === 'rsv');
      arr = arr.filter((el) => el.title !== selected?.title);
      ordered.push(selected!);
    }
  }
  return ordered;
};

export const getRandomNickname = () => {
  const firstIndex = Math.floor(Math.random() * (animals.length - 1));
  const first = animals[firstIndex];
  const secondIndex = Math.floor(Math.random() * (colors.length - 1));
  const second = colors[secondIndex];
  const third = Math.round(Math.random() * (100 - 10) + 10);

  return `${first} ${second} ${third}`;
};

export const drawImage = (
  canvas: HTMLCanvasElement,
  layout: RsvLayout,
  text: string,
  cb: () => void
) => {
  const maxRowLength = layout.maxRowLength;
  const lineHeight = 38;

  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = () => {
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    if (ctx) {
      ctx.drawImage(img, 0, 0);
      ctx.font = '26pt AG Book Regular';
      ctx.fillStyle = '#ffffff';
      ctx.textAlign = 'start';
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.shadowBlur = 2;
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';

      const rows = [''];
      let index = 0;

      text
        .split(' ')
        .filter((el) => el)
        .forEach((word) => {
          if (rows[index].length + word.length > maxRowLength) {
            index++;
            rows[index] = word + ' ';
            return;
          }
          rows[index] = rows[index] + word + ' ';
        });

      const rowHeights = rows.length * lineHeight;
      const centerDrawPointY = (layout.height - rowHeights) / 2;

      rows.forEach((text, i) => {
        ctx.fillText(
          text,
          layout.startingX,
          layout.startingY + centerDrawPointY + lineHeight * i
        );
      });

      cb();
    }
  };
  img.src = layout.image;
};

export const convertImageData64toFile = (imageData: string): File => {
  const byteCharacters = atob(imageData.split('base64,')[1]);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {type: 'image/png'});
  return new File([blob], '#rethinkrsv.png', {type: 'image/png'});
};

export const isIOS = () => {
  return ['iPhone Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  );
};
