import {initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database';

const config = {
  apiKey: 'AIzaSyCReCslSx1DuowTSCD2D-TXHkVZlubxtBk',
  authDomain: 'novonordisk-ada2020.firebaseapp.com',
  databaseURL: 'https://novonordisk-ada2020.firebaseio.com',
  projectId: 'novonordisk-ada2020',
  storageBucket: 'novonordisk-ada2020.appspot.com',
  messagingSenderId: '245271341186',
  appId: '1:245271341186:web:e6bd81bc59693218aeec9b',
  measurementId: 'G-PPFP9JK2X1',
};

const app = initializeApp(config);
export const db = getDatabase(app);
