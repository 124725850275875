import {PropsWithChildren} from 'react';
import styled from 'styled-components';
import logo from '../assets/sanofi-logo.svg';
import quizBg from '../assets/quiz-bg.jpg';
import whiteBg from '../assets/white-bg.jpg';

interface LayoutProps extends PropsWithChildren<{}> {
  bgScreen: 'quiz' | 'white';
  centered?: boolean;
  className?: string;
}

const bgMap = {
  quiz: quizBg,
  white: whiteBg,
};

export default function Layout({
  bgScreen,
  children,
  centered,
  className = '',
}: LayoutProps) {
  return (
    <MainWrapper
      className={className}
      centered={centered}
      style={{backgroundImage: `url(${bgMap[bgScreen]})`}}
    >
      <Logo src={logo} alt="Logo Sanofi" />
      {children}
    </MainWrapper>
  );
}

const MainWrapper = styled.div<{centered?: boolean}>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: ${({centered}) => (centered ? '20px' : '50px 20px 0px')};
  ${({centered}) => centered && 'justify-content: center;'}
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Logo = styled.img`
  width: 150px;
  position: absolute;
  top: 1vh;
  right: 4vw;
  z-index: 2;
`;
