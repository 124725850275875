import {useCallback, useEffect, useState} from 'react';
import {mockQuestions1, mockQuestions2} from '../config/questions';
import {pushResult} from '../utils/api';
import {
  getRandomNickname,
  getRandomQuestions,
  randomizeOrder,
  scoreCalculator,
} from '../utils/utils';

type QuizStep = 'welcome' | 'start' | 'confirm' | 'quiz' | 'end';

const TIMEOUT = 30;

const scoreCalc = (elapsed: number): number => {
  return Math.round((50000 - elapsed) / 200);
};

export default function useQuiz() {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selected, setSelected] = useState<Question>();
  const [current, setCurrent] = useState<number>(0);
  const [nickname, setNickname] = useState<string>('');
  const [step, setStep] = useState<QuizStep>('welcome');
  const [results, setResults] = useState<number[]>([]);
  const [showResult, setShowResult] = useState<number | undefined | null>();
  const [seconds, setSeconds] = useState(0);
  const [starterTime, setStarterTime] = useState<number>(0);

  const handleNickName = useCallback(() => {
    if (step === 'welcome') {
      setStep('start');
    }
    const nickname = getRandomNickname();
    setNickname(nickname);
  }, [step]);

  const handleStart = useCallback(() => {
    setStep('quiz');
    setStarterTime(Math.round(new Date().getTime()));
    setSeconds(TIMEOUT);
    setSelected(questions[current]);
  }, [questions, current]);

  const handleConfirm = useCallback(() => setStep('confirm'), []);

  const handleAnswer = useCallback(
    (index: number) => {
      if (showResult) {
        return;
      }
      const elapsed = new Date().getTime() - starterTime;
      setResults((old) => [
        ...old,
        selected?.isCorrect === index ? scoreCalc(elapsed) : 0,
      ]);
      setShowResult(index);
    },
    [selected, starterTime, showResult]
  );

  const handleTimeout = useCallback(() => {
    setResults((old) => [...old, 0]);
    setShowResult(null);
  }, []);

  const handleContinue = useCallback(async () => {
    if (current === 3) {
      await pushResult({
        nickname,
        score: scoreCalculator(results),
      });
      // TODO: handle error
      return setStep('end');
    }
    setStarterTime(Math.round(new Date().getTime()));
    setShowResult(undefined);
    setSelected(questions[current + 1]);
    setCurrent(current + 1);
    setSeconds(TIMEOUT);
  }, [current, nickname, questions, results]);

  useEffect(() => {
    const t = setInterval(() => {
      if (showResult === undefined && step === 'quiz') {
        const currentTime = Math.round(new Date().getTime() / 1000);
        const difference = currentTime - Math.round(starterTime / 1000);
        setSeconds(TIMEOUT - difference);
      }
    }, 100);

    return () => clearInterval(t);
  }, [starterTime, showResult, step]);

  useEffect(() => {
    if (seconds === 0 && step === 'quiz') {
      handleTimeout();
    }
  }, [seconds, handleTimeout, step]);

  useEffect(() => {
    const randomQuestions = randomizeOrder([
      ...getRandomQuestions(mockQuestions1),
      ...getRandomQuestions(mockQuestions2),
    ]);
    setQuestions(randomQuestions);
  }, []);

  useEffect(() => {
    console.log(results);
  }, [results]);

  return {
    step,
    nickname,
    selected,
    handleNickName,
    handleStart,
    handleAnswer,
    handleTimeout,
    handleConfirm,
    showResult,
    results,
    seconds,
    starterTime,
    handleContinue,
    current,
  };
}
