export const mockQuestions1: Question[] = [
  {
    title:
      'Quali bambini sono a rischio ospedalizzazione per bronchiolite da RSV?',
    answers: [
      'Nati a termine',
      'Nati pretermine',
      'Nati con cardiopatia congenita',
      'Tutte le precedenti',
    ],
    isCorrect: 3,
    topic: 'rsv',
  },
  {
    title:
      'Come rilevato dalla sorveglianza delle ospedalizzazioni in Toscana qual è la percentuale di bambini nati sani e a termine ospedalizzati per RSV?',
    answers: ['< 30%', '50%', '70%', '88%'],
    isCorrect: 3,
    topic: 'rsv',
  },
  {
    title: 'Qual è solitamente il periodo epidemico dell’RSV in Italia:',
    answers: [
      'Settembre – Aprile',
      'Novembre – Marzo',
      'Ottobre – Dicembre',
      'Maggio – Settembre',
    ],
    isCorrect: 1,
    topic: 'rsv',
  },
  {
    title:
      'Quali sono le percentuali di ospedalizzazione e mortalità dovuti all’infezione da RSV?',
    answers: ['5% e 0,003%', '3% e 0,005%', '2% e 0,003%', '3% e 0,002%'],
    isCorrect: 2,
    topic: 'rsv',
  },
  {
    title:
      'L’ingresso del Virus Respiratorio Sinciziale nelle cellule epiteliali delle vie respiratorie avviene per:',
    answers: [
      'Fusione della proteina C',
      'Proteina di fusione F',
      'Migrazione dell’RNA virale',
      'Enucleazione del DNA virale',
    ],
    isCorrect: 1,
    topic: 'rsv',
  },
  {
    title:
      'Durante la pandemia da Sars-CoV-2 e più precisamente, nella stagione 2020-2021, i casi di bronchiolite da RSV sono: ',
    answers: [
      'Aumentati',
      'Rimasti Invariati',
      'Quasi scomparsi',
      'Ridotti di poco',
    ],
    isCorrect: 2,
    topic: 'rsv',
  },
  {
    title:
      'La maggior parte delle ospedalizzazioni per RSV avviene nel neonato: ',
    answers: [
      'Prematuro',
      'Cardiopatico',
      'Nato a termine sano',
      'Nessuna delle precedenti',
    ],
    isCorrect: 2,
    topic: 'rsv',
  },
  {
    title:
      'Tra le soluzioni di prevenzione in fase di studio quale garantirebbe una protezione per l’intera durata della stagione in tutti i bambini nella loro prima  stagione RSV',
    answers: [
      'Anticorpi monoclonali',
      'Vaccini pediatrici',
      'Vaccini materni',
      'Nessuna delle precedenti',
    ],
    isCorrect: 0,
    topic: 'rsv',
  },
  {
    title: 'Circa il 90% dei bambini contrae l’RSV entro quale età?',
    answers: [
      'Entro i 2 anni',
      'Dopo i 5 anni',
      'Nei primi giorni di vita',
      'Tra i 4 e i 5 anni',
    ],
    isCorrect: 0,
    topic: 'rsv',
  },
  {
    title:
      'Nei bambini al di sotto dei 12 mesi, l’RSV è la causa principale di:',
    answers: [
      'Bronchiolite e polmonite',
      'Solo febbre',
      'Solo raffreddore ',
      'Nessuna delle precedenti',
    ],
    isCorrect: 0,
    topic: 'rsv',
  },
];

export const mockQuestions2: Question[] = [
  {
    title:
      'Qual è la percentuale di letalità della malattia invasiva da meningococco:',
    answers: ['< 5%', 'Tra 5-10%', 'Tra 10-15%', '> 15%'],
    isCorrect: 2,
    topic: 'meningo',
  },
  {
    title:
      'Quali possono essere le complicanze della malattia invasiva da meningococco:',
    answers: ['Meningite o sepsi', 'Infarto', 'Ictus', 'Artrite reumatoide'],
    isCorrect: 0,
    topic: 'meningo',
  },
  {
    title:
      'A partire dal 2016, in Italia è stato registrato un aumento dei casi di malattia invasiva da meningococco di:',
    answers: [
      'Sierogruppo A',
      'Sierogruppo Z',
      'Sierogruppo W',
      'Sierogruppo X',
    ],
    isCorrect: 2,
    topic: 'meningo',
  },
  {
    title:
      'Qual è la percentuale di sopravvissuti alla malattia da meningococco con sequele gravi? ',
    answers: ['0-5%', '5-10%', '10-20%', '+ di 20%'],
    isCorrect: 2,
    topic: 'meningo',
  },
  {
    title: 'Quali sono le principali sequele post infezione meningococcica? ',
    answers: [
      "Perdita dell'udito",
      'Danno neurologico',
      'Amputazione degli arti',
      'Tutte le precedenti',
    ],
    isCorrect: 3,
    topic: 'meningo',
  },
  {
    title:
      "Qual è la categoria con l'incidenza più alta di malattia meningococcica invasiva? ",
    answers: ['Bambini 0-4 anni', 'Adolescenti', 'Giovani adulti', 'Anziani'],
    isCorrect: 0,
    topic: 'meningo',
  },
  {
    title: 'Quali sono i sierogruppi responsabili della IMD? ',
    answers: ['A,B,C,W,X,Y', 'B,C,W,Y', 'A,C,X,Y', 'B,C,W,X,Y'],
    isCorrect: 0,
    topic: 'meningo',
  },
  {
    title:
      "Quali sono i principali sintomi che si osservano all'inizio dell'infezione meningococcica?",
    answers: ['Febbre', 'Irritabilità', 'Mal di gola', 'Tutte le precedenti'],
    isCorrect: 3,
    topic: 'meningo',
  },
  {
    title:
      'Entro quando si osservano i primi sintomi post infezione meningococcica?',
    answers: ['4-12 ore', '12-15 ore', '15-24 ore', '> 24 ore'],
    isCorrect: 0,
    topic: 'meningo',
  },
  {
    title:
      'In caso di contatto con una persona infetta da malattia meningococcica invasiva batterica, qual è la profilassi da seguire?',
    answers: ['Antinfiammatori', 'Antibiotici', 'Antivirali', 'Non esiste'],
    isCorrect: 1,
    topic: 'meningo',
  },
];
