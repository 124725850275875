/* eslint-disable jsx-a11y/anchor-has-content */
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../components/Layout';
import cardImg from '../assets/area-medica.png';
import Code from '../components/Code';

export default function MedicalAreaScreen() {
  const navigate = useNavigate();

  return (
    <Layout centered bgScreen="white">
      <HomeButton onClick={() => navigate('/')}>Home</HomeButton>
      <CardWrap>
        <a href="https://www.vaccini.sanofipasteur.it/registrazione-nuovo-utente" />
        <img src={cardImg} alt="area medica sanofi" />
      </CardWrap>
      <Code />
    </Layout>
  );
}
const HomeButton = styled.p`
  position: absolute;
  top: 4vh;
  left: 4vw;
  width: 40px;
  z-index: 2;
  color: ${({theme}) => theme.colors.smoke};
  font-family: ${({theme}) => theme.fonts.agbook};
  text-transform: uppercase;
  font-size: 20px;
  cursor: default;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
`;

const CardWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  a {
    position: absolute;
    top: 40%;
    right: 17%;
    width: 29%;
    height: 35%;
  }

  img {
    max-width: 50%;
  }
`;
