import {db} from '../utils/firebase';
import {ref, onValue, off, push} from 'firebase/database';
import {useState, useEffect, useCallback} from 'react';

export default function useFirebaseVal(key: any) {
  const [value, setValue] = useState(null);

  const updateValue = useCallback(
    (snapshot: any) => setValue(snapshot.val()),
    []
  );

  useEffect(() => {
    onValue(ref(db, 'sanofi-evento/' + key), (snapshot) => {
      updateValue(snapshot);
    });

    return () => off(ref(db));
  }, [key]);

  const setRemoteValue = useCallback<any>((value: any) => {
    push(ref(db, 'sanofi-evento/' + key), value);
  }, []);

  return [value, setRemoteValue];
}
