import styled, {keyframes} from 'styled-components';
import Answer from './Answer';
import quizMeningo from '../assets/quiz-meningo.png';
import quizRsv from '../assets/quiz-rsv.png';
import virusPng from '../assets/virus.png';
import Timer from './Timer';
import bottomLeft from '../assets/stars/bottom-left.png';
import topLeft from '../assets/stars/top-left.png';
import topRight from '../assets/stars/top-right.png';
import Button from './Buttons/Button';

interface QuestionProps {
  question: Question;
  handleAnswer: (value: number) => void;
  showResult: number | undefined | null;
  seconds: number;
  handleContinue: () => void;
  current: number;
}

const imgMap = {
  rsv: quizRsv,
  meningo: quizMeningo,
};

export default function QuestionWrap({
  question,
  handleAnswer,
  showResult,
  seconds,
  handleContinue,
  current,
}: QuestionProps) {
  const {title, isCorrect, answers} = question;

  return (
    <>
      <Wrap>
        <TimerWrap>
          <Timer seconds={seconds} reset={showResult !== undefined} />
        </TimerWrap>
        <Title>{title}</Title>
        <AnswerWrap>
          {answers.map((el, i) => (
            <StyledAnswer
              key={el}
              onClick={() => handleAnswer(i)}
              isCorrect={i === isCorrect}
              showResult={showResult}
              index={i}
              label={el}
            />
          ))}
        </AnswerWrap>
        {showResult !== undefined && (
          <Continue onClick={handleContinue}>
            {current === 3 ? 'Vedi classifica' : 'Prossima domanda'}
          </Continue>
        )}
      </Wrap>
      <Img src={imgMap[question.topic]} alt="child" topic={question.topic} />
      {question.topic === 'rsv' ? (
        <>
          <Virus src={virusPng} alt="virus" className="first" />
          <Virus src={virusPng} alt="virus" className="second" />
          <Virus src={virusPng} alt="virus" className="third" />
          <Virus src={virusPng} alt="virus" className="fourth" />
        </>
      ) : (
        <>
          <Stars src={bottomLeft} alt="stars" className="first" />
          <Stars src={topLeft} alt="stars" className="second" />
          <Stars src={topRight} alt="stars" className="third" />
        </>
      )}
    </>
  );
}

const virusAnimation1 = keyframes`
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(10px)
  }
`;

const virusAnimation2 = keyframes`
  from {
    transform: translateY(10px)
  }
  to {
    transform: translateY(0px)
  }
`;

const starsAnimation1 = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0.5
  }
`;

const starsAnimation2 = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0.5
  }
`;

const Wrap = styled.div`
  border-bottom: 6px solid ${({theme}) => theme.colors.red};
  border-left: 6px solid ${({theme}) => theme.colors.red};
  border-right: 6px solid ${({theme}) => theme.colors.red};
  border-radius: 50px;
  background: ${({theme}) => theme.colors.sand};
  position: relative;
  z-index: 4;
  position: relative;
  transform: translate(-13vw, 5vw);
  max-width: 650px;

  @media (max-width: 768px) {
    transform: translateX(0);
  }
`;

const Title = styled.h3`
  text-align: center;
  padding: 30px 20px;
  border: 6px solid ${({theme}) => theme.colors.red};
  border-radius: 50px;
  width: calc(100% + 12px);
  transform: translateX(-6px);
  font-weight: normal;
  color: ${({theme}) => theme.colors.red};
  font-style: italic;
  font-size: clamp(20px, 1.8vw, 30px);

  @media (max-width: 768px) {
    text-align: initial;
  }
`;

const AnswerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 6% 6% 6%;

  @media (max-width: 768px) {
    padding: 0 4% 4% 4%;
  }
`;

const StyledAnswer = styled(Answer)`
  width: 48%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Img = styled.img<{topic: 'meningo' | 'rsv'}>`
  position: absolute;
  right: ${({topic}) => (topic === 'rsv' ? '0' : '-5vw')};
  bottom: 0;
  z-index: 2;
  width: ${({topic}) => (topic === 'rsv' ? '25vw' : '50vw')};
`;

const Virus = styled.img`
  position: absolute;
  z-index: 3;

  &.first {
    top: 3vh;
    left: 10vw;
    width: 100px;
    animation: 2s linear 1s infinite alternate ${virusAnimation1};
  }

  &.second {
    top: 25vh;
    left: 42vw;
    width: 70px;
    animation: 2s linear 500ms infinite alternate ${virusAnimation2};
    transform: translateY(30px);
  }

  &.third {
    bottom: 2vh;
    left: 3vw;
    width: 130px;
    animation: 2s linear 2s infinite alternate ${virusAnimation1};
    transform: translateY(30px);
  }

  &.fourth {
    bottom: -10vh;
    left: 45vw;
    width: 200px;
    animation: 2s linear 1.5s infinite alternate ${virusAnimation2};
    transform: translateY(30px);
  }
`;

const TimerWrap = styled.div`
  position: absolute;
  top: -20vh;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    top: -15vh;
  }
`;

const Stars = styled.img`
  position: absolute;
  z-index: 0;

  &.first {
    bottom: 0vh;
    right: 0;
    width: 80vw;
    animation: 1.5s linear 1.5s infinite alternate ${starsAnimation1};
  }

  &.second {
    top: 0vh;
    left: 0;
    width: 20vw;
    animation: 500ms linear 500ms infinite alternate ${starsAnimation2};
  }

  &.third {
    top: 0vh;
    right: 0;
    width: 30vw;
    animation: 1s linear 1s infinite alternate ${starsAnimation1};
  }
`;

const Continue = styled(Button)`
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
`;
