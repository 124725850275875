import {useEffect, useMemo, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import arrowImg from '../assets/arrow.svg';
import Button from './Buttons/Button';

interface RankProps {
  rank?: RankItem[];
  nickname?: string;
}

export default function Rank({rank, nickname}: RankProps) {
  const navigate = useNavigate();
  const wrapRef = useRef<HTMLDivElement>(null);

  const sortedRank = useMemo(() => rank, [rank]);

  useEffect(() => {
    if (wrapRef.current && nickname && rank) {
      const index = rank
        .sort((a, b) => b.score - a.score)
        .findIndex((el) => el.nickname === nickname);
      const target = wrapRef.current.querySelector(
        `div:nth-child(${index + 1})`
      );
      target?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [nickname, sortedRank, rank]);

  if (!rank) {
    return (
      <>
        <PurpleText>
          Consulta la bibliografia con cui sono state realizzate
          <br /> le domande del Quiz Game - Play it Safe
        </PurpleText>
        <Wrap>
          <ScrollWrap>
            <RankWrap>
              <Row>
                <Cell full>
                  <Heading $spacing>Fonti domande rsv</Heading>
                </Cell>
              </Row>
              <Row full>
                <Circle>1</Circle>
                <Cell underlined full>
                  <Label full>
                    Hall CB et al. Pediatrics 2013; 132: e34–e348
                  </Label>
                </Cell>
              </Row>
              <Row full>
                <Circle>2</Circle>
                <Cell underlined full>
                  <Label full>
                    Bianchini S et al. Microorganisms 2020; 8(12): 2048
                  </Label>
                </Cell>
              </Row>
              <Row full>
                <Circle>3</Circle>
                <Cell underlined full>
                  <Label full>
                    Barbati F, Azzari C, et al. MDPI Vaccines, 2020
                  </Label>
                </Cell>
              </Row>
              <Row>
                <Cell full>
                  <Heading $spacing>Fonti domande meningo</Heading>
                </Cell>
              </Row>
              <Row full>
                <Circle>1</Circle>
                <Cell underlined full>
                  <Label full>
                    Sadarangani M, Scheifele DW, Halperin SA, et al.;
                    Investigators of the Canadian Immunization Monitoring
                    Program, ACTive (IMPACT). Outcomes of invasive meningococcal
                    disease in adults and children in Canada between 2002 and
                    2011: a prospective cohort study. Clin Infect Dis
                    2015;60:e27-35.{' '}
                    <a
                      href="https://doi.org/10.1093/cid/civ028"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://doi.org/10.1093/cid/civ028
                    </a>
                    .
                  </Label>
                </Cell>
              </Row>
              <Row full>
                <Circle>2</Circle>
                <Cell underlined full>
                  <Label full>
                    S. Boccalini et al. (2021) Health Technology Assessment
                    (HTA) sull’introduzione di coorti aggiuntive per la
                    vaccinazione contro il meningococco con vaccini
                    quadrivalenti coniugati in Italia.
                  </Label>
                </Cell>
              </Row>
              <Row full>
                <Circle>3</Circle>
                <Cell underlined full>
                  <Label full>
                    Istituto superiore di Sanità (ISS). Malattie invasive
                    batteriche, Epicentro. Revisione gennaio 2020.
                  </Label>
                </Cell>
              </Row>
              <Row full>
                <Circle>4</Circle>
                <Cell underlined full>
                  <Label full>
                    Istituto Superiore di Sanità (ISS). Sorveglianza delle
                    malattie batteriche invasive in Italia. Rapporto consolidato
                    2018. Luglio 2020.
                  </Label>
                </Cell>
              </Row>
              <Row full>
                <Circle>5</Circle>
                <Cell underlined full>
                  <Label full>
                    Halperin SA, Bettinger JA, Greenwood B, et al. The changing
                    and dynamic epidemiology of meningococcal disease. Vaccine
                    2012;30:B26-36.
                    <a
                      href="https://doi.org/10.1016/j.vaccine.2011.12.032"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://doi.org/10.1016/j.vaccine.2011.12.032
                    </a>
                  </Label>
                </Cell>
              </Row>
            </RankWrap>
          </ScrollWrap>
        </Wrap>
        <FlexWrap>
          <StyledButton onClick={() => navigate('/medical-area')}>
            Iscriviti all'area dedicata ai medici
          </StyledButton>
          <StyledButton onClick={() => navigate('/rethinkrsv-qrcode')}>
            #RETHINKRSV
          </StyledButton>
        </FlexWrap>
      </>
    );
  }

  return (
    <>
      {nickname && (
        <PurpleText>
          Hai totalizzato {rank.find((el) => el.nickname === nickname)?.score}{' '}
          punti
          <br />
          {rank
            .sort((a, b) => b.score - a.score)
            .findIndex((el) => el.nickname === nickname) + 1}
          ° posizione in classifica
        </PurpleText>
      )}
      <Wrap>
        <Row>
          <Cell>
            <Heading $spacing>Giocatori</Heading>
          </Cell>
          <Cell>
            <Heading>Punteggi</Heading>
          </Cell>
        </Row>
        <ScrollWrap>
          <RankWrap ref={wrapRef}>
            {rank
              .sort((a, b) => b.score - a.score)
              .map((el, i) => (
                <Row key={el.nickname} isFocused={nickname === el.nickname}>
                  <Circle isFocused={nickname === el.nickname}>{i + 1}</Circle>
                  <Cell underlined>
                    <Label $spacing>{el.nickname}</Label>
                  </Cell>
                  <Cell underlined>
                    <Label>{el.score}</Label>
                  </Cell>
                </Row>
              ))}
          </RankWrap>
        </ScrollWrap>
        <Arrow src={arrowImg} alt="" />
      </Wrap>
      <FlexWrap>
        <StyledButton onClick={() => navigate('/medical-area')}>
          Iscriviti all'area dedicata ai medici
        </StyledButton>
        <StyledButton onClick={() => navigate('/rethinkrsv-qrcode')}>
          #RETHINKRSV
        </StyledButton>
        <StyledButton onClick={() => navigate('/bibliography')}>
          Bibliografia
        </StyledButton>
      </FlexWrap>
    </>
  );
}

const Wrap = styled.div`
  background: ${({theme}) => theme.colors.sand};
  padding: 20px 30px;
  border-radius: 35px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 95vw;
  height: 60vh;
  max-width: 800px;
  margin-top: 20px;

  @media (max-width: 768px) {
    padding: 10px 10px 0px;
  }
`;

const PurpleText = styled.h2`
  text-align: center;
  color: ${({theme}) => theme.colors.purple};
  margin: 15px auto 0;
`;

const Row = styled.div<{isFocused?: boolean; full?: boolean}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 50px;
  position: relative;
  height: ${({full}) => (full ? 'auto' : '40px')};
  margin-bottom: 30px;
  border-radius: 25px;
  color: ${({theme, isFocused}) =>
    isFocused ? theme.colors.purple : theme.colors.darkGray};

  ${({isFocused}) => isFocused && `font-weight: bold;`}

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Cell = styled.div<{underlined?: boolean; full?: boolean}>`
  width: ${({full}) => (full ? '100%' : '50%')};
  display: flex;
  align-items: center;
  height: 100%;

  & + & {
    width: calc(50% - 10px);
  }

  ${({underlined, theme}) =>
    underlined && `border-bottom: 2px solid ${theme.colors.darkGray};`}
`;

const Heading = styled.h3<{$spacing?: boolean}>`
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.red};
  font-weight: 300;
  ${({$spacing}) => $spacing && 'margin-left: 30px;'}
`;

const Circle = styled.span<{isFocused?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: ${({theme, isFocused}) =>
    isFocused ? theme.colors.purple : theme.colors.white};
  color: ${({theme}) => theme.colors.darkGray};
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;

  ${({isFocused, theme}) => isFocused && `color: ${theme.colors.white};`}

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const Label = styled.p<{$spacing?: boolean; full?: boolean}>`
  ${({full}) => !full && 'text-align: center;'}
  ${({$spacing}) => $spacing && 'margin-left: 30px;'}
  ${({full}) => full && 'padding-bottom: 10px;'}
`;

const Arrow = styled.img`
  width: 40px;
`;

const ScrollWrap = styled.div`
  flex: 1;
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
`;

const RankWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FlexWrap = styled.div`
  display: flex;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  font-size: clamp(13px, 1vw, 16px);
  padding: 15px 30px;
`;
