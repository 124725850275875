import styled from 'styled-components';
import {indexToCharacter} from '../utils/utils';

interface AnswerProps {
  label: string;
  index: number;
  onClick: () => void;
  isCorrect: boolean;
  showResult: number | undefined | null;
  className?: string;
}

export default function Answer({
  label,
  index,
  onClick,
  isCorrect,
  showResult,
  className = '',
}: AnswerProps) {
  const isSelected = index === showResult;
  return (
    <Wrap
      onClick={onClick}
      isCorrect={isCorrect}
      showResult={showResult !== undefined}
      className={className}
      isSelected={isSelected}
    >
      <Circle>{indexToCharacter(index)}</Circle>
      <p>{label}</p>
    </Wrap>
  );
}
const Wrap = styled.div<{
  isCorrect: boolean;
  showResult?: boolean;
  isSelected: boolean;
}>`
  margin-top: 7vh;
  padding: 10px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  color: ${({isCorrect, showResult, theme, isSelected}) => {
    if (!showResult) {
      return theme.colors.lightGray;
    }
    if (isCorrect) {
      return 'green';
    }
    if (!isCorrect && isSelected) {
      return theme.colors.red;
    }
    return theme.colors.lightGray;
  }};
  border: 6px solid
    ${({theme, showResult, isCorrect, isSelected}) => {
      if (!showResult) {
        return theme.colors.lightGray;
      }
      if (isCorrect) {
        return theme.colors.green;
      }
      if (!isCorrect && isSelected) {
        return theme.colors.red;
      }
      return theme.colors.lightGray;
    }};

  ${({isSelected, theme}) =>
    isSelected && `background: ${theme.colors.senape};`}

  @media (max-width: 768px) {
    margin-top: 5vh;
  }
`;

const Circle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.darkGray};
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;
