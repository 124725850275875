import styled from 'styled-components';

const Button = styled.button`
  text-transform: uppercase;
  font-weight: bold;
  font-size: clamp(13px, 2vw, 18px);
  background: ${({theme}) => theme.colors.purple};
  border: none;
  cursor: pointer;
  color: ${({theme}) => theme.colors.white};
  border-radius: 30px;
  padding: 15px 40px;

  & + & {
    margin-left: 3vw;
  }

  &:hover {
    text-decoration: underline;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default Button;
