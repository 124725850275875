import React from 'react';
import {ThemeProvider} from 'styled-components';
import theme from './theme';
import {Routes, Route, Navigate} from 'react-router-dom';
import WelcomeScreen from './screens/WelcomeScreen';
import QuizScreen from './screens/QuizScreen';
import GlobalStyle from './theme/GlobalStyle';
import RankScreen from './screens/RankScreen';
import MedicalAreaScreen from './screens/MedicalArea';
import RSVScreen from './screens/RSVScreen';
import LearnMoreAboutRSVScreen from './screens/LearnMoreAboutRSVScreen';
import QRCodeScreen from './screens/QRCodeScreen';
import BibliographyScreen from './screens/BibliographyScreen';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<WelcomeScreen />} />
        <Route path="quiz" element={<QuizScreen />} />
        <Route path="rank" element={<RankScreen />} />
        <Route path="medical-area" element={<MedicalAreaScreen />} />
        <Route path="rethink-rsv" element={<RSVScreen />} />
        <Route path="rethinkrsv-qrcode" element={<QRCodeScreen />} />
        <Route
          path="learn-more-about-rsv"
          element={<LearnMoreAboutRSVScreen />}
        />
        <Route path="bibliography" element={<BibliographyScreen />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
